import { isDebugEnabled } from '@leon-hub/debug';
import { EditionValueRenderingPrerender } from '@leon-hub/environment-editions';
import { getLocationHost, getLocationPath, getQueryParams, getReferer } from '@leon-hub/service-locator-env';
import { getPrerenderIp, QueryParameterName } from '@leon-hub/query-manager';
export const languageHeaderName = 'X-APP-LANGUAGE';
export const themeHeaderName = 'X-APP-THEME';
export default function getBaseHeaders() {
    let headers = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const baseHeaders = {
        // Builtin headers
        'X-APP-PLATFORM': "web",
        'X-APP-RENDERING': "csr",
        'X-APP-MODERNITY': "modern",
        'X-APP-ENV': "prod",
        'X-APP-SKIN': "default",
        'X-APP-LAYOUT': "desktop",
        'X-APP-OS': process.env.VUE_APP_OS,
        'X-APP-BROWSER': process.env.VUE_APP_BROWSER,
        'X-APP-VERSION': "6.97.0",
        ...headers
    };
    "1";
    {
        Object.assign(baseHeaders, {
            'X-REQUESTED-URI': getLocationPath()
        });
        if (process.env.VUE_APP_PRERENDER) {
            const prerenderHeaders = {
                'X-USER-HOST': getLocationHost(),
                'X-USER-IP': getPrerenderIp(),
                'X-APP-RENDERING': EditionValueRenderingPrerender
            };
            Object.assign(baseHeaders, prerenderHeaders);
        }
        const runtimePlatform = getQueryParams()[QueryParameterName.PLATFORM];
        if (runtimePlatform) Object.assign(baseHeaders, {
            'X-APP-PLATFORM': runtimePlatform
        });
    }
    Object.assign(baseHeaders, {
        'X-APP-REFERRER': getReferer()
    });
    if (isDebugEnabled()) Object.assign(baseHeaders, {
        'X-APP-DEBUG': '1'
    });
    return baseHeaders;
}
